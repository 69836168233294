<template>
  <div>
    <el-form size="medium" label-width="auto">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item :label="$t('component.group')">
            <el-select v-model="queryParam.groupId" filterable clearable :placeholder="$t('placeholder.select') + $t('component.group')" style="width: 100%">
              <template v-for="group in groupList">
                <el-option :value="group.id" :label="group.name"/>
              </template>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('component.name')">
            <el-input v-model.trim="queryParam.name" clearable :placeholder="$t('placeholder.input') + $t('component.name')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="0">
            <el-button type="primary" @click="handleSearch(queryParam)">{{$t('button.search')}}</el-button>
            <el-button type="primary" @click="handleSearch({})">{{$t('button.reset')}}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import {list as getList} from "@/api/componentGroup";

export default {
  name: "ComponentManageListSearchBox",
  model: {
    prop: 'queryParam',
    event: 'change'
  },
  
  props: {
    queryParam: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  
  data() {
    return {
      groupList: []
    }
  },
  
  created() {
    getList({
      page: 1,
      pageSize: 999999
    }).then(res => {
      this.groupList = res.data.list
    })
  },
  
  methods: {
    handleSearch(queryParam) {
      this.$emit('change', queryParam)
      this.$nextTick(() => {
        this.$emit('onSearch')
      })
    }
  }
}
</script>